.mySlideShow {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 600px) {
    .mySlides {
        height: 100vh;
    }
}

@media only screen and (max-width: 600px) {
    .mySlides {
        width: 100vw;
    }
}

.mySlides:hover {
    cursor: zoom-in;
}

.mySlidesZoom {
    transform: scale(1.3);
    /* transform-origin: top left;
    overflow: auto; */
}

.slideNoticeZoom {
    transform-origin: top center;
    transform: scale(1.1);
}

.sliderButton {
    border: none;
    display: inline-block;
    padding: 30px 25px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    color: rgb(0, 49, 100)!important;
    background-color: rgb(212, 152, 30)!important;
}

.leftArrow {
    position: absolute;
    left: 20px;
    z-index: 5;
}
@media only screen and (min-width: 600px) {
    .leftArrow {
        top: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .leftArrow {
        top: 80%;
    }
}
.leftArrow:hover {
    animation: transform ease-in-out;
    transform: translateX(-3px)
}

.rightArrow {
    position: absolute;
    right: 20px;
    z-index: 5;
}
@media only screen and (min-width: 600px) {
    .rightArrow {
        top: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .rightArrow {
        top: 80%;
    }
}
.rightArrow:hover {
    animation: transform ease-in-out;
    transform: translateX(3px)
}

.slide-dots {
    position: fixed;
    bottom: 10px;
    margin: auto;
}

.slideDot{
    height: 20px;
    width: 20px;
    margin-left: 5px;
    background-color: rgba(187, 187, 187, 0.5);
    border-radius: 50%;
    display: inline-block;
}

.slide-dots > .active{
    background-color: rgb(212, 152, 30);
}

/*
zoomedIn class if needed. The idea is to add this class when clicking on the image
.zoomedIn {
    transform-origin: top center;
    transform: scale(2);
}
*/