.modalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    margin: auto;
    z-index: 3;
    overflow: auto;
    left: 0;
    top: 0;
}

.chatModalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    margin: auto;
    z-index: 3;
    overflow: auto;
    left: 0;
    top: 0;
}

.closeButton {
    font-size: 3em;
    color: rgb(212, 152, 30);
    position: absolute;
    top: 20px;
    right: 32px;
    z-index: 4;
    background: none;
    border: none;
    cursor: pointer;
}
.closeButton:hover {
    animation: transform ease-in-out;
    transform: scale(0.9);
}

.imgLink {
    cursor: pointer;
}