.credits-title {
    text-align: center;
    padding: 10px;
}
.credits-list {

}

.penImage {
    fill: white;
}

.job-image {
    height: 20px;
}