:root {
    --indices-faibles: #fbe38bbf;
    --indices-moyens: #ffd640bf;
    --indices-forts: #e8b90abf;
    --indices-reponses: #176ea8bf;
}

.indices-bubble {
    background-image: url("../images/avatars/indices.png") !important;
    background-color: rgb(0, 49, 100);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: 5px 5px 20px rgb(56, 55, 55);
}

.indices-bubble:hover {
    animation: transform ease-in-out;
    transform: scale(1.08);
    border: 1px solid rgba(176, 128, 22, 1);
}

.indices {
    display: flex;
    justify-content: center;
    position: fixed;
    overflow: auto;
    background-color: rgba(85, 85, 85, 0.900);
    z-index: 5;
    color: white;
}

@media only screen and (min-width: 600px) {
    .indices {
        width: 60%;
        left: 20%;
        bottom: 10%;
        height: 80%;
    }
}

@media only screen and (max-width: 600px) {
    .indices {
        width: 100%;
        left: 0%;
        bottom: 0%;
        height: 100%;
    }
}


.card {
    background: none;
}

.accordion {
    border: 5px white solid;
    border-radius: 10px;
}

.accordion > div {
    border: none;
}

.card-body {
    color: white;
}

.indices-container {
    width: 100%;
    z-index: 1;
    padding: 0% 5%;
}

.title {
    text-align: center;
    color: white;
}

h2 {
    font-size: 1.2em;
    padding-top: 20px;
}

.indices-faibles {
    background-color: var(--indices-faibles);
    cursor: pointer;
}
.indices-moyens {
    background-color: var(--indices-moyens);
    cursor: pointer;
}
.indices-forts {
    background-color: var(--indices-forts);
    cursor: pointer;
}
.indices-reponses {
    background-color: var(--indices-reponses);
    cursor: pointer;
}

#background_video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 80%;
    min-height: 100%;
}

.closeButtonIndices {
    font-size: 3em;
    color: rgb(212, 152, 30);
    position: sticky;
    top: 20px;
    left: 90%;
    z-index: 4;
    background: none;
    border: none;
    cursor: pointer;
}
.closeButtonIndices:hover {
    animation: transform ease-in-out;
    transform: scale(0.9);
}