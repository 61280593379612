/* CHAT GENERAL */
.rsc {
    text-align: left;
}

/* BOUTON D'ACTIVATION DU CHAT */
.rsc-float-button {
    background-image: url("../../images/avatars/guizmo.png") !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    bottom: 10% !important;
    right: 5% !important;
    animation-name: pulse-heavy;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    z-index: 1 !important;
    box-shadow: 5px 5px 20px rgb(56, 55, 55) !important;
}
.rsc-float-button > svg {
    display: none;
}
.rsc-float-button:hover {
    border: 1px solid rgba(176, 128, 22, 1);
}

/* FENETRE CHAT OUVERTE */
.rsc-container {
    width: 40% !important;
    min-width: 600px;
    height: 80% !important;
    bottom: 10% !important;
    right: 5% !important;
    z-index: 2 !important;
}

@media only screen and (max-width: 600px) {
    .rsc-container {
        width: 100% !important;
        min-width: 0px;
        height: 100% !important;
        bottom: 0% !important;
        top: 0 !important;
        left: 0 !important;
    }
}

/* HEADER DU CHAT */
.rsc-header {
    background-color: rgba(176, 128, 22, 1) !important;
}

/* INTERIEUR DU CHAT */
.cLiekP, .rsc-content {
    height: 80% !important;
    background-color: rgba(255, 255, 255, 0.8) !important;

}

/* FOOTER - LA OU ON RENTRE LE TEXTE*/
.rsc-footer {
    position: absolute;
    bottom: 0;
}

/* BULLE DE DIALOGUE ROBOT */
.rsc-ts-bot > .rsc-ts-bubble {
    background-color: rgba(21, 76, 176, 1) !important;
    width: 60%;
    max-width: 60%;
    overflow: auto;
}

.rsc-ts-bubble > div > a > img {
    max-height: 100px;
}

/* BULLES OPTIONS DIALOGUE */
.rsc-os-options {
    text-align: right;
}

.options-chemins {
    list-style: none;
    display: flex;
}

.rsc-os-option {
    margin-left: 5px;
}

/* BULLE SEULE */
.rsc-os-option-element {
    background-color: rgba(176, 128, 22, 1) !important;
    /*color: rgba(64, 64, 64, 1) !important;*/
    color: #fff;
    animation-name: pulse;
    animation-duration: 1.1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    border: 0;
    border-radius: 22px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
    color: #fff;
    display: inline-block;
    font-size: 14px;
    padding: 12px;
}

/* BOUTON DE VALIDATION DES REPONSES */
.bouton-validation {
    background-color: rgb(212, 152, 30);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 1.125em;
    animation: pulse;
}
.bouton-validation:hover  {
    background-color: rgb(245, 163, 0);
    color: white;
}

/* ANIMATION BOUTON */
@keyframes pulse {
    from {
    transform: scale3d(1, 1, 1);
    }

    50% {
    transform: scale3d(1.02, 1.02, 1.02);
    }

    to {
    transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse-heavy {
    from {
    transform: scale3d(1, 1, 1);
    }

    50% {
    transform: scale3d(1.1, 1.1, 1.1) translateY(-1em);
    }

    to {
    transform: scale3d(1, 1, 1);
    }
}