/* Display desktop in the background */
.desktop-background {
    margin: 0 auto;
    background-image: url("../images/background/loadescape_accueil.jpg");
    background-size: cover;
    background-repeat: repeat;
    background-position: top left;
}

/* TUTORIAL */
.tutorial-popup {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    z-index: 1;
    overflow: auto;
    left: 0;
    top: 0;
    color: white;
    align-items: center;
    justify-content: center;
}

.tutorial-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 70%;
    margin: auto;
}

.tutorial-content > h1 {
    font-size: 1.6em;
    margin-bottom: 1.5rem;
}

.tutorial-content > p {
    font-size: 100%;
}

.pcIconTuto {
    max-width: 150px;
}


/* DESKTOP VIEW */
@media only screen and (min-width: 601px) {
    .desktop-background {
        height: 100vh;
    }
    .tutorial-content {
        height: 100vh;
    }
    .tutorial-popup {
        position: fixed;
    }
}

@media only screen and (max-width: 600px) {
    .tutorial-content {
        width: 90%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .container {
        height: auto;
    }
}