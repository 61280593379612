.structure {
    margin: 0 auto;
    background-image: url("../images/background/loadescape_accueil.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    height: 100vh;
    display: flex;
}

.structure-body {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    z-index: 1;
    overflow: auto;
    left: 0;
    top: 0;
    color: white;
    height: 100vh;
    padding: 0vw 15vw 0vw 15vw;
}

.structure-banniere {
    max-height: 300px;
    width: 100%;
}