.scoreList {
    list-style-type: none;
    padding: 0;
}

.progress {
    display: flex;
    justify-content: flex-start;
    border-radius: 200px;
    align-items: center;
    position: relative;
    padding: 0 2px;
    display: flex;
    height: 20px;
    width: 300px;
    background-color: rgb(0, 49, 100);
}
  
.progress-value {
    animation: load0 3s normal forwards;
    box-shadow: 0 10px 30px -10px rgb(245, 163, 0);
    border-radius: 200px;
    background: rgb(212, 152, 30);
    height: 20px;
    width: 0;
}
@keyframes load0 {
    0% { width: 0; }
    100% { width: 0%; }
}

@keyframes load10 {
    0% { width: 0; }
    100% { width: 10%; }
}
@keyframes load20 {
    0% { width: 0; }
    100% { width: 20%; }
}
@keyframes load30 {
    0% { width: 0; }
    100% { width: 30%; }
}
@keyframes load40 {
    0% { width: 0; }
    100% { width: 40%; }
}
@keyframes load50 {
    0% { width: 0; }
    100% { width: 50%; }
}
@keyframes load60 {
    0% { width: 0; }
    100% { width: 60%; }
}
@keyframes load70 {
    0% { width: 0; }
    100% { width: 70%; }
}
@keyframes load80 {
    0% { width: 0; }
    100% { width: 80%; }
}
@keyframes load90 {
    0% { width: 0; }
    100% { width: 90%; }
}
@keyframes load100 {
    0% { width: 0; }
    100% { width: 100%; }
}