.vigilants-desktop {
    margin: 0 auto;
    background-image: url("../images/background/loadescape_accueil.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    height: 100vh;
    display: flex;
}

/* Use multiple backgrounds urls depending on the moment of the game */
.paris {
    background-image: url("../images/background/B&W_paris_vivian-chow-KScAV3Zvs94-unsplash.jpg");
}
.appartement {
    background-image: url("../images/background/living-room-581073_1920.jpg");
}
.egypte {
    background-image: url("../images/background/arrivee_adam-bichler-Nsi6jZ9tRZo-unsplash.jpg");
}
.kheops {
    background-image: url("../images/background/arrivee2_boesijana-fcIs1i60ix4-unsplash.jpg");
    background-position: center center;
}
.pyramide {
    background-image: url("../images/background/interieur-pyramide_fedy-ben-yedder-TYwKIwfXlXo-unsplash.jpg");
    background-position: center center;
}
.interieur {
    background-image: url("../images/background/interieur-pyramide2_joel-van-schoonhoven-r6Ic2Q1ffoE-unsplash.jpg");
}

.hidden {
    display: none !important;
}

/* BOUTON DES INDICES */
.dot {
    height: 58px;
    width: 58px;
    background-color: rgb(0, 49, 100);
    border-radius: 50%;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    bottom: 10%;
    left: 20%;
    position: fixed;
    cursor: pointer;
}
.dot > a {
    color: white;
}
.dot > a:hover {
    color: white;
    text-decoration: none;
}

/* GENERAL */
.hidden {
    display: none;
}

.img-desktop {
    max-height: 100%;
}
/* Bouton */
.button {
    background-color: rgb(212, 152, 30);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 1.125em;
}
.button:hover {
    background-color: rgb(245, 163, 0);
    color: white;
    transform: scale(1.05);
    animation: transform ease-in-out;
}
.buttonEquipeB {
    background-color: rgb(0, 49, 100);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 1.125em;
}
.buttonEquipeB:hover {
    background-color: rgb(10, 81, 156);
    color: white;
    transform: scale(1.05);
    animation: transform ease-in-out;
}

/* TIMER */
.timer {
    position: absolute;
    top: 91vh;
    left: 50vw;
}

.timer > h3 {
    font-family: 'Josefin Slab', serif;
    font-size: 2.1875em;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px;
    margin: auto;
    border-radius: 20px;
}

/* ALGOS DETECTION */
.algos {
    display: flex;
    position: absolute;
    top: 91vh;
    left: 45vw;
    color: white;
}

.algos > p {
    background: rgba(240, 69, 69, 0.8);
    margin: auto;
    border-radius: 20px;
    padding: 5px;
    margin: auto;
    font-size: 1.285em;
    animation-name: pulse;
    animation-duration: 1.1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    font-family: 'Josefin Slab', serif;
}

.container {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    height: 90vh;
    margin: auto;
    overflow: hidden;
}

.icones {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(3, 1fr);
    margin: 0% 0% 0% 0%;
    justify-items: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 3%;
    border-radius: 20px;
}

/* MODIFICATION DE LA VISIONNEUSE DE DOCUMENTS */
.awssld__content > img {
    object-fit: contain !important;
}

/* Couleur des flèches de défilement */
.awssld {
    --organic-arrow-color: #fff !important;
}

/* PLACEMENT DES ICONES EN GRID */
.bloc {
    max-width: 100px;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 10px;
}

.bloc:hover {
    border: solid 2px rgb(212, 152, 30);
    border-radius: 20px;
}

.icon {
    width: 100%;
    cursor: pointer;
}